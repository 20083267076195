import { Auth } from "aws-amplify";

export const getSessionHeaders = async () => {
  if (window?.assureConfig?.dev) {
    return {};
  }

  try {
    const response = await Auth.currentSession();
    const token = response?.accessToken?.jwtToken;
    if (!token) {
      const locationPath = window.location.href;
      window.location.href = `${window.location.origin}${window.assureConfig.loginPath}?redirectPath=${locationPath}`;
    }
    return { Authorization: `Bearer ${token}` };
  } catch (err) {
    const locationPath = window.location.href;
    window.location.href = `${window.location.origin}${window.assureConfig.loginPath}?redirectPath=${locationPath}`;
  }
};
