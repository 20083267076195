import React from "react";
import Console from "./common/Console";
import {
  DxcApplicationLayout,
  HalstackProvider,
} from "@dxc-technology/halstack-react";
import linkedinLogo from "./images/LinkedinIcon";
import twitterLogo from "./images/TwitterIcon";
import facebookLogo from "./images/FacebookIcon";
import { Header, useAssureTheme } from "@diaas/assure-platform-react-tools";
import styled from "styled-components";

const CustomFooter = () => {
  return (
    <DxcApplicationLayout.Footer
      copyright={`© DXC Technology ${new Date().getFullYear()}. All rights reserved.`}
      bottomLinks={[
        {
          href: "https://www.linkedin.com/company/dxctechnology",
          text: "Linkedin",
        },
        {
          href: "https://twitter.com/dxctechnology",
          text: "Twitter",
        },
        {
          href: "https://www.facebook.com/DXCTechnology/",
          text: "Facebook",
        },
      ]}
      socialLinks={[
        {
          href: "https://www.linkedin.com/company/dxctechnology",
          logo: linkedinLogo,
        },
        {
          href: "https://twitter.com/dxctechnology",
          logo: twitterLogo,
        },
        {
          href: "https://www.facebook.com/DXCTechnology/",
          logo: facebookLogo,
        },
      ]}
    ></DxcApplicationLayout.Footer>
  );
};

function App() {
  const { isThemeLoaded, theme } = useAssureTheme();

  document.getElementsByTagName("link")[0].href = theme.favicon;
  document.title = theme.tabTitle
    ? `${theme.tabTitle} Platform Console`
    : "Platform Console";
  const companyName = theme.companyName;

  return (
    (isThemeLoaded === "loading" && <LoadingBackground />) ||
    ((isThemeLoaded === "loadedSuccess" || isThemeLoaded === "loadedError") && (
      <HalstackProvider theme={theme.components}>
        <DxcApplicationLayout header={<Header />} footer={<CustomFooter />}>
          <DxcApplicationLayout.Main>
            <Console companyName={companyName} />
          </DxcApplicationLayout.Main>
        </DxcApplicationLayout>
      </HalstackProvider>
    ))
  );
}

const LoadingBackground = styled.div`
  background-color: #000000cc;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export default App;
